var ua = window.navigator.userAgent;
var isIE = /MSIE|Trident/.test(ua);

if (isIE) {
    $("body").addClass("ie");
}

jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    collapseComponent();
    colorPicker();
    // colorSearchInput();

    $(".change-url").on("change", function () {
        window.location.assign($(this).val());
    });

    if ($(window).width() < 1024.98) {
        $(".filter-toggle").on("click", function () {
            $(".product-filter-wrap").slideDown();
            $("body").addClass("overflow-hidden");
        });

        $(".btn-filter-close").on("click", function () {
            $(".product-filter-wrap").slideUp();
            $("body").removeClass("overflow-hidden");
        });
    }

    $(".expand-toggle").on("click", function (e) {
        e.preventDefault();
        $(this).prev().toggleClass("show");
        $(this).find("strong").toggleText("View more", "Collapse");
        $(this).find("span").toggleClass("lnr-arrow-down lnr-arrow-up");
    });

    $(".product-detail-attr .tab-content .expand-content").each(function () {
        if ($(this).height() < 480) {
            $(this).next().hide();
        } else {
            $(this).next().show();
        }
    });

    yearSliderInit();
    $(".tabs").on("_after", function () {
        $(".product-detail-attr .tab-content .expand-content").each(
            function () {
                if ($(this).height() < 480) {
                    $(this).next().hide();
                } else {
                    $(this).next().show();
                }
            }
        );
        // yearSliderInit();
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
        }, 100);
    });

    productCategorySliderInit();
    if ($(window).width() > 1024) {
        $(window).resize(function () {
            $(".product-home-slider").css({
                "margin-left":
                    parseInt($(".home-2 .container").css("margin-left")) +
                    15 +
                    "px",
            });
        });
    }

    $(".about-page .about-1").prepend($(".about-page .breadcrumb-wrapper"));
});

$.fn.extend({
    toggleText: function (a, b) {
        return this.text(this.text() == b ? a : b);
    },
});

function collapseComponent() {
    $(document).on(
        "click",
        ".has-collapse:not(.active) .collapse-toggle",
        function () {
            $(this).find("span").toggleText("add", "remove");
            $(this).parent().next().slideToggle();
            $(this).parents(".has-collapse").toggleClass("opened");
            return false;
        }
    );
    $(document).on("click", ".faq-trigger", function () {
        $(this).find("span").toggleText("add", "remove");
        $(this).next().slideToggle();
        $(this).toggleClass("active");
        return false;
    });
}

if (!isIE) {
    try {
        const menuMapping = new MappingListener({
            selector: ".menu-wrapper",
            mobileWrapper: ".offcanvas-wrap",
            mobileMethod: "appendTo",
            desktopWrapper: ".header-left",
            desktopMethod: "prependTo",
            breakpoint: 1024.98,
        }).watch();
    } catch (error) {}
}

var otherItemsSlide = new Swiper(".other-list-slide .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
        prevEl: ".other-list-slide .swiper-prev",
        nextEl: ".other-list-slide .swiper-next",
    },
    breakpoints: {
        1024.98: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        },
    },
});

var productSlide = new Swiper(".product-slide .swiper-container", {
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
        prevEl: ".product-slide .swiper-prev",
        nextEl: ".product-slide .swiper-next",
    },
    breakpoints: {
        1024.98: {
            slidesPerView: 2.5,
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
        },
        576: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        },
    },
});

var projectThumbnailSlide = new Swiper(".project-thumbnail .swiper-container", {
    slidesPerView: 4,
    spaceBetween: 20,
    observeParents: true,
    observer: true,
    breakpoints: {
        1024.98: {
            slidesPerView: 3.5,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 10,
        },
    },
});

var projectImagesSlide = new Swiper(".project-images .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 30,
    observeParents: true,
    observer: true,
    navigation: {
        prevEl: ".project-thumbnail .swiper-prev",
        nextEl: ".project-thumbnail .swiper-next",
    },
    thumbs: {
        swiper: projectThumbnailSlide,
    },
});

var productThumbnailSlide = new Swiper(
    ".product-thumb-slide .swiper-container",
    {
        slidesPerView: 4,
        spaceBetween: 10,
        observeParents: true,
        observer: true,
        breakpoints: {
            1024.98: {
                slidesPerView: 3.5,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2.5,
                spaceBetween: 10,
            },
        },
    }
);

var productImagesSlide = new Swiper(".product-images-slide .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 30,
    observeParents: true,
    observer: true,
    navigation: {
        prevEl: ".product-thumb-slide .swiper-prev",
        nextEl: ".product-thumb-slide .swiper-next",
    },
    thumbs: {
        swiper: productThumbnailSlide,
    },
});

function yearSliderInit() {
    $(".history-wrap").each(function (index) {
        var $this = $(this);
        $this.addClass("history-wrap-" + index);
        $this
            .find(".swiper-year-btn.swiper-prev")
            .addClass("swiper-year-prev-" + index);
        $this
            .find(".swiper-year-btn.swiper-next")
            .addClass("swiper-year-next-" + index);

        let yearDotSlider = new Swiper(
            ".history-wrap-" + index + " .year-dot-slider .swiper-container",
            {
                slidesPerView: 6,
                observeParents: true,
                observer: true,
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    576: {
                        slidesPerView: 2,
                    },
                },
            }
        );

        let yearContentSlider = new Swiper(
            ".history-wrap-" +
                index +
                " .year-content-slider .swiper-container",
            {
                slidesPerView: 1,
                spaceBetween: 30,
                observeParents: true,
                observer: true,
                navigation: {
                    prevEl: ".year-dot-slider .swiper-year-prev-" + index,
                    nextEl: ".year-dot-slider .swiper-year-next-" + index,
                },
                thumbs: {
                    swiper: yearDotSlider,
                },
            }
        );
    });
}

var homeBannerSlide = new Swiper(".home-banner .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 30,
    speed: 1300,
    loop: true,
    effect: "fade",
    fadeEffect: {
        crossFade: true,
    },
    autoplay: {
        delay: 4000,
    },
});

var homeProjectCaptionSlider = new Swiper(
    ".project-caption-slider .swiper-container",
    {
        slidesPerView: 1,
        speed: 1300,
        observeParents: true,
        observer: true,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
    }
);

var homeProjectImageSlider = new Swiper(
    ".project-image-slider .swiper-container",
    {
        slidesPerView: 1,
        speed: 1300,
        observeParents: true,
        observer: true,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        pagination: {
            el: ".project-image-slider-wrap .swiper-pagination",
            clickable: true,
        },
        thumbs: {
            swiper: homeProjectCaptionSlider,
        },
    }
);

function productCategorySliderInit() {
    if ($(window).width() > 1024) {
        $(".product-home-slider").css({
            "margin-left":
                parseInt($(".home-2 .container").css("margin-left")) +
                15 +
                "px",
        });
    }
    var productHomeSlider = new Swiper(
        ".product-home-slider .swiper-container",
        {
            slidesPerView: 3.75,
            spaceBetween: 30,
            scrollbar: {
                el: ".product-home-slider-scrollbar .swiper-scrollbar",
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                },
                576: {
                    slidesPerView: 1,
                },
            },
        }
    );
}

function colorPicker() {
    $(document).on("click", ".color-select", function (e) {
        e.preventDefault();
        var colorName = $(this).data("colorname"),
            colorCode = $(this).data("colorcode"),
            colorInfo = $(this).data("colorinfo"),
            pageInfo = $(this).data("pageinfo");

        if ($(this).hasClass("selected")) {
            $(`.selected-color-item[data-colorCode='${colorCode}']`).remove();
            $(this).removeClass("selected");
        } else {
            $(".selected-color-list").prepend(
                `<div class="selected-color-item" data-colorCode='${colorCode}'>
                <div class="color-pallete" style="background: ${colorCode}">
                <a class="color-popBtn color-copy" href="#" data-colorcodename='${colorInfo}'></a>
                <a class="color-popBtn color-fullscreen" href="#" data-colorCode='${colorCode}' data-colorInfo='${colorInfo}' data-colorName='${colorName}'></a>
                </div>
                <div class="color-info">
                <strong>${colorName}</strong>
                <span>${colorInfo} (${pageInfo})</span>
                </div>
            </div>`
            );
            $(this).addClass("selected");
        }

        if ($(window).width() < 1024.98) {
            $(".selected-color-list-wrap").addClass("open");
            $("body").addClass("overflow-hidden");
        }
    });

    $(document).on("click", ".selected-color-list-wrap .close", function () {
        $(".selected-color-list-wrap").removeClass("open");
        $("body").removeClass("overflow-hidden");
    });

    $(document).on("click", ".color-copy", function (e) {
        e.preventDefault();
        var textCopy = $(this).data("colorcodename");
        copyToClipboard(textCopy);
        alert("Copied successfully");
    });

    $(document).on("click", ".color-fullscreen", function (e) {
        e.preventDefault();
        var colorName = $(this).data("colorname"),
            colorCode = $(this).data("colorcode"),
            colorInfo = $(this).data("colorinfo");

        $(".popup-color .color-screen").css({
            backgroundColor: colorCode,
        });

        $(".popup-color .color-copy").data("colorcodename", colorInfo);
        $(".popup-color .color-title strong").text(colorName);
        $(".popup-color .color-title span").text(colorInfo);

        $(".popup-color").fadeIn();
    });

    $(document).on("click", ".popup-color .btn-close", function (e) {
        $(".popup-color").fadeOut();
    });
}

function colorSearchInput() {
    $("#searchColorTxt").on("keyup", function (e) {
        if (e.key === "Enter" || e.keyCode === 13) {
            e.preventDefault();
            var searchTxt = $(this).val().toLowerCase();
            searchFunction(searchTxt);
        }
    });

    $(".color-input-submit").on("click", function () {
        var searchTxt = $("#searchColorTxt").val().toLowerCase();
        searchFunction(searchTxt);
    });

    function searchFunction(searchTxt) {
        $(".color-select").filter(function () {
            $(this)
                .parent()
                .toggle(
                    $(this).data("colorname").toLowerCase().indexOf(searchTxt) >
                        -1 ||
                        $(this)
                            .data("colorcodename")
                            .toLowerCase()
                            .indexOf(searchTxt) > -1
                );
        });
    }
}

const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
};
